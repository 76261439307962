.loader{
  margin-top: 50%
}
.logo {
  display: flex;
  position: relative;
  height: 12.5rem;
  border-radius: 50%;
  background: white url(../assets/Jibe_LOGO.png) no-repeat center;
  background-size: 80%;
  }

@import '../../variables';

.button {
  position: fixed;
  right: 1.5rem;
  bottom: 1.5rem;
  height: 4rem;
  width: 4rem;
  color: var(--gray-0);
  background: $gradient;
  border-radius: 50%;
  font-size: 2rem;
  z-index: 100;
}

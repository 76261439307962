@import "variables";

@mixin with-colors {
  @each $color, $shades in $colors {
    @each $shade, $hex in $shades {
      --#{$color + ""}-#{$shade}: #{$hex};
    }
  }
}

@mixin with-pill {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1rem;
  background-color: var(--gray-0);

  &::after {
    content: "";
    display: block;
    width: $daySize;
    height: 3px;
    background-color: var(--gray-500);
    opacity: .25;
  }
}

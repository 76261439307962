.wrapper {
  padding: 1.5rem 0;
  display: flex;
  border-bottom: 1px solid var(--gray-200);
}

.icon {
  color: var(--gray-300);
  opacity: 0.4;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.2rem 0 0 .75rem;
}

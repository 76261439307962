.status {
  position: absolute;
  top: 0;
  left: 50%;
  // width: fit-content;
  padding: .1875rem .375rem;
  color: var(--gray-500);
  background-color: var(--green-100);
  font-size: .8125rem;
  border-radius: 4px;
  text-align: center;
  text-transform: uppercase;
  transform: translateX(-50%);
}

.button {
  text-align: start;
}

.inactive {
  color: var(--gray-300);
}

.dropDown{
  padding: 1rem 1.25rem;
  border-radius: 8px;
  font-size: .8125rem;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--blue-400);
  background: var(--gray-0);
  box-shadow: 0 0 0 1px inset var(--blue-400);
}
.dialog {
  top: env(safe-area-inset-top);
  background-color: var(--gray-0);
  padding: 1.25rem;
  border-radius: 16px 16px 0 0;
  overflow-y: scroll;
}

.header {
  display: flex;
}

.title {
  margin: auto;
  font-size: .8125rem;
  text-transform: uppercase;
}

.list {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.spacer {
  margin-top: 1.375rem;
}

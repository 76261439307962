.wrapper {
  margin: 0 1.5rem;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  font-weight: 500;
  font-size: .8125rem;
  text-transform: uppercase;

  & .title {
    color: var(--gray-300);
  }

  & .link {
    color: var(--blue-400);
  }
}

.list {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.header {
  top: 3.5rem;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 10rem;
  height: calc(100vh - 13rem);
}

.message {
  margin-bottom: 0.75rem;
  color: var(--gray-300);
  font-size: .8125rem;
  text-align: center;
  text-transform: uppercase;
}

.select {
  display: flex;
  flex-direction: column;
  margin: 1rem 1.25rem 4.5rem;

  & > button {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.startButton {
  display: flex;
  margin: 2rem 0;
  justify-content: center !important;
}
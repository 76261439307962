
.radialProgress {
  position: absolute;
  left: -8px;
  right: -8px;
  height: calc(100% + 16px);
  width: calc(100% + 16px);
}

.circleBackground {
  stroke: #ddd;
}

.circleBackground,
.circleProgress {
  fill: none;
}

.circleProgress {
  stroke: var(--gray-0);
}

.lighting {
  margin-left: 50%;
  color: var(--green-100);
}

.circleText {
  font-weight: 400;
  font-family: 'Roboto';
  font-size: .5rem;
  fill: white;
}
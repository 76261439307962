
.recurrenceContainer {
  margin-top: 1.5rem;
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.endRecurrence{
  font-size: 0.875rem;  
  text-transform: uppercase;

  & > .toggle {
    color: var(--gray-300);
  }
  & > .error {
    color: var(--red-200);
  }
}
.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
}

.icon {
  height: inherit;
}

.overlay {
  position: absolute;
}

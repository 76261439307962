@import "../../variables";

.weekdays {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: $daySize;
  margin-left: -100%;
  padding: 0 .75rem;
  background-color: var(--gray-0);
  z-index: 1;
}

.day {
  width: $daySize;
  color: var(--gray-300);
  font-size: .75rem;
  text-align: center;
}

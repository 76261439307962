.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 200px);
  padding-top: calc(3rem + env(safe-area-inset-top));
}

.header {
  position: absolute;
  top: calc(1rem + env(safe-area-inset-top));
  padding: 1rem;
  color: var(--gray-0);
  font-size: 1.375rem;
  backdrop-filter: blur(8px);
}

.pill {
  position: relative !important;
  left: 0 !important;
  margin-top: .5rem !important;
  transform: none !important;
  margin: auto;
}

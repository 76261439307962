.timeline {
  position: relative;
}

.slot {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: calc(100% - 4rem);
  align-items: center;
  height: 48px;
  margin-left: 4rem;
  padding-right: 1rem;
  color: var(--gray-300);
  border: 1px solid var(--gray-200);
  border-bottom-width: 0;
  border-right-width: 0;
  font-size: .75rem;
  text-transform: uppercase;

  &::before {
    content: attr(data-time);
    position: absolute;
    top: -.5rem;
    left: -2.75rem;
    font-weight: 500;
  }
}

.unavailable {
  background-color: var(--gray-100);

  &::before, &::after {
    opacity: .5;
  }

  &::after {
    content: ""; 
  }
}

.time {
  position: absolute;
  left: 4rem;
  width: calc(100% - 4rem);
  height: 2px;
  background-color: var(--blue-300);
}

.container {
  position: relative;
  height: 13.875rem;
  width: 13.125rem;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.wrapperHours {
  margin: 0 0 0 2.25rem;
  position: absolute;
  color: var(--gray-300);
  font-size: 1.0625rem;
  width: 4.3125rem;
}

.timeWrapper {
  width: 8.625rem;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 0;
  margin: 0 auto;

  span {
    &:nth-child(1) {
      margin: 0 0 0 calc(1.5rem - 1.0625rem);
    }

    &:nth-child(2) {
      margin: 0 calc(1.5rem - 1.0625rem) 0 0;
    }
  }
}

.selectedTimeWrapper {
  width: 8.625rem;
  margin: 1.5rem auto;
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  color: var(--gray-500);
}

.pickedContainer {
  position: absolute;
  height: 4.5rem;
  width: 13.125rem;
  border: 2px solid var(--blue-200);
  border-radius: 3px;
  top: 4.6875rem;
}

.borderCutVertical {
  margin: -2px auto;
  background-color: var(--gray-100);
  width: 10.125rem;
  height: 4.5rem;
}

.column {
  position: absolute;
  border-radius: 3px;
  top: 5.7rem;
  left: 6.4375rem;
  font-size: 1.5rem;
  color: var(--gray-300);
}

.wrapperMinutes {
  margin: 0 0 0 2.25rem;
  position: absolute;
  color: var(--gray-300);
  font-size: 1.0625rem;
  width: 4.3125rem;
  left: 50%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: .5rem;
}

.label {
  color: var(--gray-300);
  font-size: .875rem;
  font-weight: 500;
  text-transform: uppercase;
}

.input {
  padding: 1.25rem;
  background-color: var(--gray-200);
  border-radius: 8px;
}

.withError {
  box-shadow: 0 0 0 1px inset var(--red-200);
}

.error {
  display: inline-block;
  height: 1.5rem;
  color: var(--red-200);
  font-size: .875rem;
  overflow: hidden;
}

.wrapper {
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 11rem;
  height: 11rem;
  color: var(--gray-0);
  border: 2px solid var(--gray-0);
  border-radius: 50%;
  text-transform: uppercase;
}

.progress {
  position: absolute;
  left: -8px;
  right: -8px;
  height: calc(100% + 16px);
  width: calc(100% + 16px);
  border: 8px solid var(--gray-0);
  border-right-color: transparent;
  border-radius: 50%;
  transform: rotate(45deg);
}

.lighting {
  margin-bottom: .875rem;
  color: var(--green-100);
}

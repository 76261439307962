.wrapper {
  margin-bottom: 6.5rem;
}

.charging {
  margin-bottom: 10rem;
}

.floating {
  bottom: 5rem;
}

.chargingScreen {
  display: none;
}
.prompt {
  top: 50% !important;
}

.content {
  display: flex;
  flex-direction: column;
  height: calc(50vh - 2.5rem);
}

.logo {
  height: 4rem;
  margin: 1rem 0 2.5rem;
}

.title {
  margin-bottom: 1rem;
  font-size: .8125rem;
  text-align: center;
  text-transform: uppercase;
}

.spacer {
  flex: 1;
}

.cancel {
  color: var(--gray-500);
  background: transparent;
}

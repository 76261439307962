.details {
  position: absolute;
  top: 3.5rem;
  width: 100%;
  z-index: 1;
}

.info{
  padding-bottom: 0rem !important ;
}

.month{
  visibility: visible;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: { left:1.25rem ; right: 1.25rem ; };
  background-color: var(--gray-0);
  z-index: 1;
}

.calendar {
  top: calc(11.5rem + env(safe-area-inset-top)) !important;
}

.timeline {
  margin-top: 15.5rem;
  margin-bottom: 6rem;
}

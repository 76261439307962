.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.toggle {
  display: flex;
  justify-content: space-between;
  align-self: center;
  width: 100%;

  &:not(.error)::after {
    content: attr(data-value);
    color: var(--gray-300);
    font-size: .8125rem;
    font-weight: 500;
  }
}

.details {
  padding-top: 1.5rem;
  border-top: 1px solid var(--gray-200);

  & > .toggle {
    font-size: .8125rem;
    text-transform: uppercase;
  }

  & > .error {
    color: var(--red-200);
  }
}

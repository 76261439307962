.wrapper {
  position: absolute;
  top: 0;
  width: 100%;
}

.details {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 1.25rem;
}

.detail {
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: .25rem;
  color: var(--gray-300);
  font-size: .8125rem;
  text-transform: uppercase;
}

.actions {
  display: flex;
  flex-direction: column;
}

.button {
  margin: 0 1.25rem 64px;
}

.collapse {
  align-self: center;
  color: var(--gray-0);
  background-color: var(--blue-300);
  border-radius: 50%;
}

.popup, .backdrop {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.backdrop {
  top: 0;
  background-color: var(--gray-500);
}

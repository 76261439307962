@import "../../variables";

.date {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: $daySize;
  border-radius: 50%;
  font-size: .75rem;
  font-weight: 500;
}

.reserved::after {
  content: "";
  position: absolute;
  display: block;
  top: 1.5rem;
  width: 3px;
  height: 3px;
  background-color: var(--blue-200);
  border-radius: 50%;
}

.selected {
  color: var(--gray-0);
  background: $gradient;

  &.reserved::after {
    background-color: var(--gray-0);
  }
}

.today {
  background-color: var(--blue-100);
}

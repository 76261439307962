@import "../../variables";
@import "../../utils";

.header {
  position: absolute;
  top: calc(3.5rem + env(safe-area-inset-top));
  width: 100%;
  padding-top: 100vh;
  background-color: var(--gray-0);
  box-shadow: $shadow-08;
  z-index: 1;
  overflow: hidden;
}

.pill {
  @include with-pill;

  z-index: 1;
}

.top {
  position: relative;
  z-index: 1;
}

.bottom {
  position: absolute;
  bottom: 1rem;
}

.details {
  margin: 1.25rem;
}

.anchor {
  display: inline-block;
  margin-top: .5rem;
  color: var(--blue-300);
  font-size: .8125rem;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
}

@import '../../variables';

.card {
  display: flex;
  width: calc(100% - 3rem);
  flex-direction: column;
  margin: .375rem 1.25rem;
  padding: 1rem;
  background-color: var(--gray-0);
  border-radius: 8px;
  text-align: start;
  box-shadow: $shadow-08, 0 0 0 1px inset var(--gray-200);
}

.location {
  color: var(--gray-500);
  font-size: 1.0625rem;
}

.city {
  color: var(--gray-300);
  font-size: .8125rem;
  text-transform: uppercase;
}

.details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 2.125rem;
  color: var(--gray-300);
  font-size: .8125rem;
  text-transform: uppercase;
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 0.75rem;
  color: var(--gray-300);
  font-size: .8125rem;
  text-transform: uppercase;
}
.arrow {
  width: 1rem;
  color: var(--gray-500);
}

.edit {
  width: 1.5rem;
  color: var(--gray-500);
}

.charging {
  background: $gradient center;
  box-shadow: $shadow-08;

  & .location, & .city, & .details, & .arrow {
    color: white;
  }
}

.charged {
  background: rgba(74, 165, 253, .07);
}

.notCharged{
  background: rgba(74, 165, 253, .07);

  .iconWrapper{
    color: var(--red-200);
    margin-right: 0.5625rem;

    svg{
      height: 0.875rem;
      width: 0.875rem;
    }
  }
}

.failed{
  background: rgba(74, 165, 253, .07);
  
  .iconWrapper{
    color: var(--red-200);
    margin-right: 0.5625rem;

    svg{
      height: 0.875rem;
      width: 0.875rem;
    }
  }
}

.pending {
  border: 1px solid var(--blue-500);
  box-shadow: $shadow-08;
}
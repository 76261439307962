.messageContainer {
  height: 100vh;
  width: 100vw;
  background: rgba(55, 55, 55, .3);
  z-index: 999;
  position: fixed;
  top: 0;
}

.messageCard {
  margin: auto 2rem 4rem 2rem;
  padding: 1rem;
  border-radius: 7px;
  background: var(--gray-100);
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
}

.close {
  margin: auto 0 auto auto;
}

.messageContent {
  margin: 1rem;
  display: flex;
}

.messageIcon {
  color: var(--red-200);
  margin-right: 1rem;
}
.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 16rem;
  margin: auto;
  color: var(--gray-300);
  font-size: .8125rem;
  text-align: center;
  text-transform: uppercase;
}

.icon {
  margin-bottom: 1rem;
  color: var(--gray-200);

  & > * {
    height: 5rem;
  }
}

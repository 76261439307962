.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 1.5rem
}

.logo {
  margin: 3.75rem auto 3.125rem auto;
  height: 5.75rem;
}

.title {
  font-size: .8125rem;
  margin: 0 auto 3.5rem auto;
  text-transform: uppercase;
}

.button {
  margin: auto 0 0 0;
}

@media screen and (min-width: 991px) {
  .pageContainer{
    background: var(--gray-200);
    height: 100vh;
    display: flex;
  }

 .container{
   margin: auto;
   width: 38.75rem;
   height: 40rem;
   padding: 0 7.5rem 5.25rem 7.5rem;
   background: var(--gray-100);
  }   
}

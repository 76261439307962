.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.75rem 1.25rem 0;
  z-index: 101;
}

.search {
  padding: 1rem 1rem 1rem 3rem;
  background-color: var(--gray-200);
  border-radius: 8px;
  font-size: .8125rem;
  font-weight: 500;
  outline: none;

  &::placeholder {
    color: var(--gray-300);
    text-transform: uppercase;
  }
}

.icon {
  position: absolute;
  left: 2.1rem;
  color: var(--gray-300);
  pointer-events: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.lists {
  margin-top: 2.5rem;
}

.item {
  display: flex;
  align-items: center;
  padding-bottom: 1.5rem;
  color: var(--gray-500);
  text-align: start;
  text-decoration: none;
}

.icon {
  margin-right: .875rem;
  color: var(--gray-300);
}

.results {
  margin-top: 7rem;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.25rem;
  background-color: var(--gray-0);
  z-index: 1;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: .25rem;
  color: var(--grey-500);
  font-size: 1.375rem;
}

.favorite {
  display: flex;
  align-items: center;
  gap: .5rem;
  color: var(--gray-300);
  font-size: .8125rem;
  text-transform: uppercase;
}

.image {
  height: 5.5rem;
  width: 5.5rem;
  margin-left: 1rem;
  border-radius: 4px;
  object-fit: cover;
}

.settings {
  padding: env(safe-area-inset-top) 1.25rem 0;
  background-color: var(--gray-0);
}

.header {
  display: flex;
  align-items: center;
  height: 4.25rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  border-bottom: 1px solid var(--gray-200);
}

.back {
  height: .65rem;
  margin: 0 1rem 2px 0;
}

.logout {
  padding: 1.25rem 0;
  color: var(--red-200);
  background: transparent;
}

.anchor {
  display: inline-block;  
  color: var(--blue-300);
  font-size: .8125rem;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.menuOption {
  display: inline-block;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;  
  border-bottom: 1px solid var(--gray-200);
  margin: 0.75rem;
}

.feedback {
  width: 1.5rem; 
  margin-right: 1.5rem; 
}

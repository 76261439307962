@import "../../variables";
@import "../../utils";

.calendar {
  position: fixed;
  top: 0;
  width: 300%;
  padding: 100vh 100% 0;
  background-color: var(--gray-0);
  box-shadow: $shadow-08;
  overflow: hidden;
}

.month {
  display: grid;
  grid-template-columns: repeat(7, $daySize);
  row-gap: 4px;
  justify-content: space-between;
  width: 100vw;
  padding: 0 .75rem;
}

.pill {
  @include with-pill;

  margin-left: -200%;
}
